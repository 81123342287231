<template>
  <loader v-bind="{ loading }">
    <template v-if="authenticated && user.password_changed">
      <div class="app-shade" @click="toggleSidebar" :class="activeClass"/>
      <side-bar :active="activeClass"/>
    </template>
    <section 
      class="app-main" 
      :class="{ 
        'unauthenticated': !authenticated || !user.password_changed
      }"
      :style="appMainStyle">
      <div class="app-content" :class="{ 'unauthenticated is-fullheight': !authenticated || !user.password_changed  }">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
      <footer class="app-footer" v-if="authenticated">
        <div class="is-flex align-center flex-shrink-none">
          <img v-if="!company.branding || !company.branding.app_logo"
            :src="appLogoUrl" class="assetpool-brand mr">
          <p>
            <template v-if="(company.branding && company.branding.app_logo)">{{ appName }}</template>
            <span class="ml has-text-weight-bold">{{ $root.version }}</span>
          </p>
        </div>
        <dropdown-wrapper position="top-end">
          <action-button
            slot="trigger"
            slot-scope="{ isActive }"
            class="is-link is-inverted is-small is-rounded has-text-weight-bold"
            :right-icon="isActive ? 'chevron-down' : 'chevron-up'"
            v-if="isSuper"
          >
            Support
          </action-button>

          <dropdown-item v-if="isSuper" @click="openUserPicker">
            <icon icon/>
            <span>Provide Support</span>
          </dropdown-item>
        </dropdown-wrapper>
      </footer>
    </section>
    <dialogs-wrapper name="calendar" class="modal-dialogs calendar-popovers" transition-name="fade"/>
    <dialogs-wrapper class="modal-dialogs" transition-name="fade"/>
    <toaster position="bottom center" :max="1"/>
    <div v-if="appNeedsUpdate" class="refresh-wrapper">
      <div class="has-background-fp-blue has-text-light p-2 has-shadow">
        <p class="has-text-weight-bold mb-1">A new version of {{ appName }} is available.</p>
        <action-button 
          @click="refreshApplication"
          left-icon="sync">Click to update</action-button>
      </div> 
    </div> 
  </loader>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SideBar from '@/components/nav/SideBar'
import { newSupportTicket, impersonateUser } from '@/modals'
import FaviconParser from '@/utils/favicon-parser'

export default {

  name: 'app',

  components: {
    SideBar
  },

  data: () => ({
    sidebarOpen: false,
    loading: false,
    apiBase: process.env.VUE_APP_BASE_URL
  }),

  beforeCreate(){
    FaviconParser.parseFavicon();
  },

  created() {
    document.title = process.env.VUE_APP_NAME
  },
  
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.restrainSidebar)
      this.restrainSidebar()
    })
  },

  methods: {
    closeSidebar() {
      this.sidebarOpen = false
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen
    },
    restrainSidebar() {
      if (document.documentElement.clientWidth >= 1024 && this.sidebarOpen) {
        this.closeSidebar()
      }
    },
    async openSupportTicket() {
      let ticket = await newSupportTicket()
      if (ticket) {
        this.$toast.success(this.$lang.support.ticketOpened)
        this.$router.push({
          name: 'support.ticket',
          params: { ticket: ticket.reference }
        })
      }
    },
    hasRole(name) {
      return this.user.roles.filter(role => role.name === name).length > 0
    },
    refreshApplication() {
      location.reload()
    },
    openUserPicker() {
      impersonateUser()
    }
  },

  computed: {
    ...mapGetters('auth', [
      'authenticated',
      'user',
      'isSuper'
    ]),
    ...mapState('company', [
      'company'
    ]),
    ...mapGetters('global', [
      'appNeedsUpdate'
    ]),
    activeClass() {
      return {
        'is-active': this.sidebarOpen
      }
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    appLogoUrl() {
      return this.company.branding && this.company.branding.app_logo_url ? this.company.branding.app_logo_url : process.env.VUE_APP_COLOUR_LOGO
    },
    appMainStyle() {
      if(this.authenticated) {
        return null;
      }

      return `
        background: #66757d url(${process.env.VUE_APP_LOGIN_IMAGE}) no-repeat 50% 50%;
        background-size: cover;
        margin-left: 0;
        padding-top: 0;
        padding-bottom: 0;
      `
    }
  },

}
</script>

<style lang="scss">
@import "./assets/app.scss";
</style>
